import {
  CONFIG_RESPONSE_SCHEMA,
  ConfigResponse,
  DEFAULT_CONFIG,
  LOGIN_RESPONSE_SCHEMA,
  LoginRequest,
  LoginResponse,
  QA_DOCUMENT_INDEX_RESPONSE_SCHEMA,
  QA_DOCUMENT_RESPONSE_SCHEMA,
  QA_RESOURCES_RESPONSE_SCHEMA,
  QA_SOURCES_RESPONSE_SCHEMA,
  QaDocumentIndexRequest,
  QaDocumentIndexResponse,
  QaDocumentRequest,
  QaDocumentResponse,
  QaResourcesResponse,
  QaSourcesRequest,
  QaSourcesResponse,
  TranslateRequest,
  TRANSLATION_RESOURCES_RESPONSE_SCHEMA,
  TRANSLATION_RESPONSE_SCHEMA,
  TranslationResourcesResponse,
  TranslationResponse,
} from '@/@core/models/api';
import { Favicon } from '@/@core/models/api/faviconResponse';
import {
  FeedbackEndpoint,
  FeedbackRequest,
  FeedbackRequestWithUiInformation,
} from '@/@core/models/api/feedback';
import { GraphQaDocumentRequest } from '@/@core/models/api/graphrag';
import {
  PNG_CONTENT_TYPE,
  PngBlobResponse,
  PngContentType,
  SVG_CONTENT_RESPONSE_SCHEMA,
  SVG_CONTENT_TYPE,
  SvgContentResponse,
  SvgContentType,
} from '@/@core/models/api/imageResponse';
import { Logo } from '@/@core/models/api/logoResponse';
import {
  SummaryDocumentRequest,
  SUMMARY_RESOURCES_RESPONSE_SCHEMA,
  SUMMARY_RESPONSE_SCHEMA,
  SummaryResourcesResponse,
  SummaryResponse,
  SummaryTextRequest,
} from '@/@core/models/api/summary';
import {
  TRANSCRIPTION_DATA_STAGE_URL_RESPONSE_SCHEMA,
  TRANSCRIPTION_RESPONSE_SCHEMA,
  TranscriptionDataStageUrlResponse,
  TranscriptionJobStatusRequest,
  TranscriptionJobStatusResponse,
  TranscriptionRequest,
  TranscriptionJobResponse,
  TRANSCRIPTION_JOB_STATUS_RESPONSE_SCHEMA,
} from '@/@core/models/api/transcription';
import { HTTP_CLIENT, KY_HTTP_CLIENT } from '@/@core/services/api/httpClient';
import { handleRequestError } from '@/@core/services/api/utils/errorHandling';
import {
  validateKyResponseData,
  validateResponseData,
} from '@/@core/services/api/utils/validateResponseData';
import { http, Http } from '@aleph-alpha/lib-http';
import { KyInstance, KyResponse } from 'ky';

const UI_VERSION: string = import.meta.env.VITE_APP_VERSION || 'unknown';

export class AssistantService {
  constructor(
    readonly httpClient: Http,
    // TODO: AST-668 (use only one client)
    readonly kyHttpClient: KyInstance
  ) {}

  async login(body: LoginRequest): Promise<LoginResponse> {
    const response = await handleRequestError(
      async () =>
        await this.httpClient.post<LoginResponse>('sign-in', {
          body,
        })
    );

    return validateResponseData(response, LOGIN_RESPONSE_SCHEMA);
  }

  async config(): Promise<ConfigResponse> {
    const response = await handleRequestError(
      async () => await this.httpClient.get<ConfigResponse>('config')
    );

    return validateResponseData(response, CONFIG_RESPONSE_SCHEMA);
  }

  async configWithDefaults(): Promise<ConfigResponse> {
    try {
      return await this.config();
    } catch (error: unknown) {
      console.log('Error when getting config from API, using default values', error);
      return DEFAULT_CONFIG;
    }
  }

  async favicon(): Promise<Favicon | undefined> {
    try {
      return await this.svgFavicon();
    } catch (error: unknown) {
      console.log('Getting SVG favicon from the API failed:', error);
    }
    try {
      return await this.pngFavicon();
    } catch (error: unknown) {
      console.log('Getting SVG and PNG favicon from the API failed:', error);
      return undefined;
    }
  }

  protected async svgFavicon(): Promise<SvgContentResponse> {
    const response = await this.getFaviconByType(SVG_CONTENT_TYPE);
    return await validateKyResponseData(response, SVG_CONTENT_RESPONSE_SCHEMA);
  }

  protected async pngFavicon(): Promise<PngBlobResponse> {
    const response = await this.getFaviconByType(PNG_CONTENT_TYPE);
    return await response.blob();
  }

  protected async getFaviconByType(
    contentType: SvgContentType | PngContentType
  ): Promise<KyResponse> {
    return await handleRequestError(
      async () =>
        await this.kyHttpClient.get('/api/favicon', {
          headers: {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            Accept: contentType,
          },
        })
    );
  }

  async logo(): Promise<Logo | undefined> {
    try {
      return await this.svgLogo();
    } catch (error: unknown) {
      console.log('Getting SVG logo from the API failed:', error);
    }
    try {
      return await this.pngLogo();
    } catch (error: unknown) {
      console.log('Getting SVG and PNG logo from the API failed:', error);
      return undefined;
    }
  }

  protected async svgLogo(): Promise<SvgContentResponse> {
    const response = await this.getLogoByType(SVG_CONTENT_TYPE);
    return await validateKyResponseData(response, SVG_CONTENT_RESPONSE_SCHEMA);
  }

  protected async pngLogo(): Promise<PngBlobResponse> {
    const response = await this.getLogoByType(PNG_CONTENT_TYPE);
    return await response.blob();
  }

  protected async getLogoByType(contentType: SvgContentType | PngContentType): Promise<KyResponse> {
    return await handleRequestError(
      async () =>
        await this.kyHttpClient.get('/api/logo', {
          headers: {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            Accept: contentType,
          },
        })
    );
  }

  async translate(body: TranslateRequest): Promise<TranslationResponse> {
    const response = await handleRequestError(
      async () =>
        await this.httpClient.post<TranslationResponse>('translate', {
          body,
        })
    );

    return validateResponseData(response, TRANSLATION_RESPONSE_SCHEMA);
  }

  async translationResources(): Promise<TranslationResourcesResponse> {
    const response = await handleRequestError(
      async () => await this.httpClient.get<TranslationResourcesResponse>('translation/resources')
    );

    return validateResponseData(response, TRANSLATION_RESOURCES_RESPONSE_SCHEMA);
  }

  async qaDocument(request: QaDocumentRequest): Promise<QaDocumentResponse> {
    const formData = new FormData();
    Object.entries(request).forEach(([key, value]) => {
      formData.append(key, value);
    });

    const response = await handleRequestError(
      async () =>
        await this.httpClient.post<QaDocumentResponse>('qa/document', {
          body: formData,
        })
    );
    return validateResponseData(response, QA_DOCUMENT_RESPONSE_SCHEMA);
  }

  async qaDocumentIndex(body: QaDocumentIndexRequest): Promise<QaDocumentIndexResponse> {
    const response = await handleRequestError(
      async () => await this.httpClient.post<QaDocumentIndexResponse>('qa/document-index', { body })
    );
    return validateResponseData(response, QA_DOCUMENT_INDEX_RESPONSE_SCHEMA);
  }

  async qaSources(request: QaSourcesRequest): Promise<QaSourcesResponse> {
    const response = await handleRequestError(
      async () => await this.httpClient.get<QaSourcesResponse>(`qa/sources/${request.trace_id}`)
    );
    return validateResponseData(response, QA_SOURCES_RESPONSE_SCHEMA);
  }

  async qaResources(): Promise<QaResourcesResponse> {
    const response = await handleRequestError(
      async () => await this.httpClient.get<QaResourcesResponse>('qa/resources')
    );

    return validateResponseData(response, QA_RESOURCES_RESPONSE_SCHEMA);
  }

  async summaryText(body: SummaryTextRequest): Promise<SummaryResponse> {
    const response = await handleRequestError(
      async () => await this.httpClient.post<SummaryResponse>('summary/raw', { body })
    );
    return validateResponseData(response, SUMMARY_RESPONSE_SCHEMA);
  }

  async summaryDocument(request: SummaryDocumentRequest): Promise<SummaryResponse> {
    const formData = new FormData();
    Object.entries(request).forEach(([key, value]) => {
      formData.append(key, value);
    });
    const response = await handleRequestError(
      async () =>
        await this.httpClient.post<SummaryResponse>('summary/document', { body: formData })
    );
    return validateResponseData(response, SUMMARY_RESPONSE_SCHEMA);
  }

  async summaryResources(): Promise<SummaryResourcesResponse> {
    const response = await handleRequestError(
      async () => await this.httpClient.get<SummaryResourcesResponse>('summary/resources')
    );

    return validateResponseData(response, SUMMARY_RESOURCES_RESPONSE_SCHEMA);
  }

  async transcriptionDataStageUrl(): Promise<TranscriptionDataStageUrlResponse> {
    const response = await handleRequestError(
      async () =>
        await this.httpClient.get<TranscriptionDataStageUrlResponse>(
          'transcription/file/data-stage-url'
        )
    );

    return validateResponseData(response, TRANSCRIPTION_DATA_STAGE_URL_RESPONSE_SCHEMA);
  }

  async transcriptionJobStatus(
    request: TranscriptionJobStatusRequest
  ): Promise<TranscriptionJobStatusResponse> {
    const response = await handleRequestError(
      async () =>
        await this.httpClient.get<TranscriptionJobStatusResponse>(
          `transcription/status/${request.job_id}`
        )
    );

    return validateResponseData(response, TRANSCRIPTION_JOB_STATUS_RESPONSE_SCHEMA);
  }

  async transcription(body: TranscriptionRequest): Promise<TranscriptionJobResponse> {
    const response = await handleRequestError(
      async () =>
        await this.httpClient.post<TranscriptionJobResponse>('transcription', {
          body,
        })
    );

    return validateResponseData(response, TRANSCRIPTION_RESPONSE_SCHEMA);
  }

  async transcriptionDelete(): Promise<void> {
    // TODO: endpoint to delete the transcription?
  }

  async postFeedback(body: FeedbackRequest, requestUrl: FeedbackEndpoint): Promise<void> {
    const request: FeedbackRequestWithUiInformation = {
      ...body,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      ui_version: UI_VERSION,
    };

    await handleRequestError(
      async () =>
        await this.httpClient.post(requestUrl, {
          body: request,
        })
    );
  }
  async graphQuestion(request: GraphQaDocumentRequest): Promise<QaDocumentResponse> {
    console.log('send');
    const formData = new FormData();
    Object.entries(request).forEach(([key, value]) => {
      formData.append(key, value);
    });

    const response = await handleRequestError(async () =>
      (
        await fetch('http://localhost:8001/qa/graphrag/question', {
          body: JSON.stringify(request),
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
        })
      ).json()
    );
    return response;
  }
}

export const ASSISTANT_SERVICE = new AssistantService(HTTP_CLIENT, KY_HTTP_CLIENT);
