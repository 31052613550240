import { z } from 'zod';

const defaultApplicationName: string = 'Assistant';
const defaultMaxFileUploadSizeMb: number = 20;
const defaultMaxAudioFileUploadSizeMb: number = 25;
const defaultMaxQuestionLengthChars: number = 1000;
const defaultRequestTimeoutInSeconds: number = 180;

export const CONFIG_RESPONSE_SCHEMA = z.object({
  maxFileUploadSizeMB: z.number().min(0).default(defaultMaxFileUploadSizeMb),
  maxAudioFileUploadSizeMB: z.number().min(0).default(defaultMaxAudioFileUploadSizeMb),
  maxQuestionLengthChars: z.number().min(1).default(defaultMaxQuestionLengthChars),
  applicationName: z.string().min(1).default(defaultApplicationName),
  requestTimeoutInSeconds: z.number().min(0).default(defaultRequestTimeoutInSeconds),
});

export type ConfigResponse = z.infer<typeof CONFIG_RESPONSE_SCHEMA>;

export const DEFAULT_CONFIG: ConfigResponse = {
  maxFileUploadSizeMB: defaultMaxFileUploadSizeMb,
  maxAudioFileUploadSizeMB: defaultMaxAudioFileUploadSizeMb,
  maxQuestionLengthChars: defaultMaxQuestionLengthChars,
  applicationName: defaultApplicationName,
  requestTimeoutInSeconds: defaultRequestTimeoutInSeconds,
} as const;
