import { FeatureFlag, FeatureFlags } from '@/@core/models/FeatureFlags';
import { AUTH_CALLBACK_GUARD, AUTH_GUARD } from '@/@core/services/auth/auth';
import { FeatureFlagClient } from '@/@core/services/feature-flag/FeatureFlagClient';
import { graphRagRoute } from '@/modules/GraphRag/route';
import { homepageRoute } from '@/modules/Homepage/route';
import { qaRoute } from '@/modules/Qa/route';
import { summaryRoute } from '@/modules/Summary/route';
import { translationRoute } from '@/modules/Translation/route';
import { usecaseQaRoute } from '@/modules/UsecaseQa/route';
import { transcriptionRoute } from '@/modules/transcription/route';
import {
  redirectToTranslationRouteIfHomepageDisabled,
  withConfig,
} from '@/router/navigationGuards';
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';

// Extend RouteMeta interface for TypeScript
declare module 'vue-router' {
  interface RouteMeta {
    requiresAuth?: boolean;
  }
}

const featureRoutes = [
  { flag: FeatureFlag.ENABLE_QA, route: qaRoute },
  { flag: FeatureFlag.ENABLE_GRAPHRAG, route: graphRagRoute },
  { flag: FeatureFlag.ENABLE_TRANSLATE, route: translationRoute },
  { flag: FeatureFlag.ENABLE_TRANSCRIBE, route: transcriptionRoute },
  { flag: FeatureFlag.ENABLE_SUMMARIZE, route: summaryRoute },
  { flag: FeatureFlag.ENABLE_USECASES, route: usecaseQaRoute },
];

const loginCallbackRoute = {
  path: '/callback',
  name: 'LoginCallback',
  beforeEnter: AUTH_CALLBACK_GUARD,
} as RouteRecordRaw;

const notFoundRoute: RouteRecordRaw = {
  name: 'NotFound',
  path: '/:pathMatch(.*)*',
  component: () => import('@/@core/views/NotFound.vue'),
};

const initializeRouterWithFeatureFlags = (featureFlag: FeatureFlagClient<FeatureFlags>) => {
  const rootRoute: RouteRecordRaw = {
    path: '/',
    name: 'App',
    component: () => import('@/@core/views/BaseView.vue'),
    children: [homepageRoute],
    beforeEnter: [withConfig, redirectToTranslationRouteIfHomepageDisabled],
    meta: {
      requiresAuth: true,
    },
  };

  const routes: RouteRecordRaw[] = [rootRoute, loginCallbackRoute, notFoundRoute];

  featureRoutes.forEach(({ flag, route }) => {
    if (featureFlag.getFlag(flag, true)) {
      rootRoute.children.push(route);
    }
  });

  const router = createRouter({
    history: createWebHistory(),
    routes,
  });

  router.beforeEach(AUTH_GUARD);

  return router;
};

export { initializeRouterWithFeatureFlags };
