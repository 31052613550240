export interface FeatureFlags {
  enableHomepage?: boolean;
  enableSummarize?: boolean;
  enableQa?: boolean;
  enableTranslate?: boolean;
  enableTranscribe?: boolean;
  enableUsecases?: boolean;
  enableGraphRag?: boolean;
}
export enum FeatureFlag {
  ENABLE_HOMEPAGE = 'enableHomepage',
  ENABLE_SUMMARIZE = 'enableSummarize',
  ENABLE_QA = 'enableQa',
  ENABLE_GRAPHRAG = 'enableGraphRag',
  ENABLE_TRANSLATE = 'enableTranslate',
  ENABLE_TRANSCRIBE = 'enableTranscribe',
  ENABLE_USECASES = 'enableUsecases',
}
